import lodashGet from 'lodash/get'
import last from 'lodash/last'

export default {
  props: {
    value: {
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
    label: {
      type: String
    },
    allowClean: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      required: true
    },
    blur: {
      type: Function
    }
  },
  data() {
    return {
      path: null,
      field: null
    }
  },
  computed: {
    attrs() {
      return this.$attrs
    },
    disabled() {
      const hasDisabled = this.attrs.hasOwnProperty('disabled')
      const hasReadOnly = this.attrs.hasOwnProperty('readonly')
      return (hasDisabled && !!this.attrs.disabled) || (hasReadOnly && !!this.attrs.readonly)
    },
    cmpValue: {
      get() {
        return lodashGet(this.$store.state, this.path.join('.'))
      },
      set(v) {
        this.$store.commit('SET_STATE', {path: this.path, value: v})
      }
    },
    cmpErrors: {
      get() {
        return lodashGet(this.$store.state.errors, this.path.join('.'), [])
      },
      set(v) {
        this.$store.commit('SET_ERRORS', {path: this.path, value: v})
      }
    }
  },
  methods: {
    getBlur (data, event) {
      if (!this.blur) {
        return function () {}
      }
      return this.blur(data, event)
    }
  },
  created() {
    this.path = this.name.match(/([a-zA-Z0-9_])+|(\[\])/g);

    this.$store.commit('SET_STATE', {path: this.path, value: this.value});
    this.$store.commit('SET_ERRORS', {path: this.path, value: this.errors});

    if(last(this.path) === '[]')
      this.path.pop()
  }
}
