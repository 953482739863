<template>
  <div class="trueconf">
    <iframe class="hidden" id="conference" allowfullscreen="allowfullscreen" allow="microphone; camera; autoplay; display-capture" :src="url"></iframe>
  </div>
</template>

<script>
import ActionCable from 'actioncable'

export default {
  name: 'Trueconf',
  props: {
    url: {
      type: String,
      required: true
    },
    backpath: {
      type: String,
      required: true
    },
    claim_id: {
      default: ""
    }
  },
  mounted: function () {
    let trueconf = this;

    window.addEventListener('message', function (e) {
      if (e.data.type == 'connectionClosed') {
        Turbolinks.visit(trueconf.backpath);
      }
    });

    this.consumer = ActionCable.createConsumer();
    this.cable = this.consumer.subscriptions.create( {channel: "EventsChannel", claim: this.claim_id},
      {
        received: (data) => {
          if (data.event == "updated") {
            Turbolinks.visit(trueconf.backpath);
          }
        }
      }
    )
  },
  destroyed () {
    this.consumer.disconnect();
    this.consumer.subscriptions.remove(this.cable);
  }
}
</script>

<style lang="scss">
  .trueconf {
    margin-bottom: 49px !important;
    position: relative;

    .quality-notification {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      top: 0; right: 0; bottom: 0; left: 0;
      background: rgba(#d2e5f9, .7);
      form {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0 10px;
      }
      .quality-box__body {
        max-width: 100%;
      }
    }
  }

  #conference {
    width: 100%;
    height: 657px;
  }
</style>
