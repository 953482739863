import toastr from "toastr"
import Bus from "../services/bus"
let self = null

class ErrorHandler {
  constructor() {
    this._type = 'ErrorHandler';
    self = this

    Bus.$on('api:error', function(error) {
      if (self.cb) {
        self.cb(error)
      } else {
        self.defaultHandler(error)
      }
    })
  }

  setHandler(cb) {
    this.cb = cb
  }

  handle(error) {
    console.error(error)
    Bus.$emit('api:error', error)
  }

  static staticMethod() {
    return 'staticMethod';
  }

  defaultHandler(error) {
    if (error.title) toastr['error'](error.title)
  }

}


export default new ErrorHandler();
