<template>
  <mfc-input-box :errors="cmpErrors" :isCleaned="false">
    <label>
      <input type="hidden" :value="cmpValue" :name="name"/>
      <input type="checkbox"
             :value="value"
             :checked="shouldBeChecked"
             @change="update"
             v-bind="attrs">
      <span class="label" :class="{small: smallText, medium: mediumText}" v-html="label"></span>
      <span class="mark" row="cn"></span>
    </label>
  </mfc-input-box>
</template>

<script>
  import inputMixin from "../../mixins/inputMixin"
  import MfcInputBox from './MfcInputBox'

  export default {
    name: "MfcCheckbox",
    components: {MfcInputBox},
    mixins: [inputMixin],
    model: {
      prop: 'modelValue',
      event: 'change'
    },
    props: {
      modelValue: {
        default: false
      },
      smallText: {
        default: false
      },
      mediumText: {
        default: false
      },
      value: {
        default: true
      },
      checked: {
        default: false
      }
    },
    data() {
      return {
        isChecked: this.checked
      }
    },
    computed: {
      shouldBeChecked() {
        if (this.localModel instanceof Array) {
          return this.localModel.includes(this.value)
        }
        return this.isChecked
      }
    },
    mounted() {
      this.setValue()
    },
    methods: {
      update(e) {
        this.isChecked = e.target.checked
        this.setValue()
      },
      setValue() {
        if (this.localModel instanceof Array) {
          let newValue = [...this.localModel]

          if (this.isChecked) {
            newValue.push(this.value)
          } else {
            newValue.splice(newValue.indexOf(this.value), 1)
          }

          this.localModel = newValue
          this.cmpValue = this.localModel
          this.$emit('change', newValue)
        } else {
          this.localModel = this.isChecked ? this.value : false
          this.cmpValue = this.localModel
          this.$emit('change', this.localModel)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/variables';
  @import '../../assets/styles/mfcicons.autogen';

  $label-height: 24px;

  label {
    position: relative;
    display: inline-block;
    min-height: $label-height;
    line-height: $label-height;
    padding-left: 37px;
    cursor: pointer;
    user-select: none;
    font-weight: 500;
  }

  .input-box:not([disabled="disabled"]) {
    label {
      &:hover .mark {
        background-color: rgb(223, 222, 218);
      }

      &:active .mark {
        background-color: rgb(195, 194, 190);
      }

      &:hover input:checked ~ .mark {
        background-color: rgb(227, 66, 38);
      }
    }
  }

  .input-box[disabled="disabled"] {
    .label, label {
      cursor: default;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .mark {
      background-color: $primary-color-middle;
    }

    &:checked ~ .mark::after {
      display: flex;
    }

    &:disabled ~ .mark {
      cursor: default;
      background-color: rgb(245, 244, 242);
    }

    &:disabled ~ .label {
      cursor: default;
      color: rgb(223, 222, 218);
    }

    &:checked:disabled ~ .mark {
      cursor: default;
      background-color: rgb(244, 189, 165);
    }

    &:checked:disabled ~ .label {
      cursor: default;
      color: rgb(223, 222, 218);
    }
  }

  .label {
    &.small {
      color: rgb(139, 135, 132);
      font-size: 14px;
      line-height: 16px;
    }

    &.medium {
      font-size: 16px;
    }
  }

  .mark {
    position: absolute;
    top: 0;
    left: 0;
    height: $label-height;
    width: $label-height;
    font-size: $label-height - 9px;
    border-radius: 6px;
    background-color: rgb(237, 236, 232);

    &::after {
      @extend .mfcicon-base-mixin;
      @extend .mfcicon-checked-mixin;
      position: absolute;
      display: none;
      color: #ffffff;
    }
  }

</style>
