import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import MfcSelect from '../form/MfcSelect'


const departments = {
  props: ["currentQuestionSubject", "departmentsList", "paramsId", "path"],
  data () {
    return {
      current: {

      }
    }
  },
  watch: {
    currentQuestionSubject (n) {
      if (!isEmpty(n)) {
        let value = find(this.departmentsList, (d) => {
          return d.id == n.department_id;
        })
        let path = this.path || ["claim", "department_id"]
        this.$store.commit('SET_STATE', {path: path, value: value})
        this.$store.commit('SET_ERRORS', {path: ["claim", "department_id"],  value: []})
      }
    }
  },
  computed: {
    departments() {
      let filteredDepartmentsList = this.departmentsList
      if (this.$store.state.citizenHasInvalidRegistration) {
        filteredDepartmentsList = filter(filteredDepartmentsList, (d) => {
          return !d.claims_only_from_region
        })
      }
      return filteredDepartmentsList
    }
  },
  mounted () {
    if (this.paramsId) {
      let value = find(this.departmentsList, (d) => {
        return d.id == this.paramsId;
      })
      this.$parent.setDepartment(value)
    }
  }
}

const questions = {
  props: ["currentDepartment", "questionSubjectsList", "questionSubject", "path"],
  data () {
    return {
      current: {

      }
    }
  },
  computed: {
    questionSubjects() {
      let filteredQuestionSubjectsList = this.questionSubjectsList;
      if (this.$store.state.citizenHasInvalidRegistration) {
        filteredQuestionSubjectsList = filter(filteredQuestionSubjectsList, (q) => {
          return !q.claims_only_from_region;
        });
      }
      if (this.currentDepartment) {
        filteredQuestionSubjectsList = filter(filteredQuestionSubjectsList, (q) => {
          return q.department_id == this.currentDepartment.id
        });
      }
      return filteredQuestionSubjectsList;
    }
  },
  watch: {
    currentDepartment(newDepartment) {
      if (!isEmpty(newDepartment)) {
        if (this.$store.state.claim.question_subject_id && (this.$store.state.claim.question_subject_id.department_id != newDepartment.id)) {
          let path = this.path || ["claim", "question_subject_id"]
          this.$parent.questionSubject = this.$parent.currentQuestionSubject = null;
          this.$store.commit('SET_STATE', {path: path, value: null})
        }
      }
    },
  },
}

export default {
  components: {
    departments: departments,
    questions: questions,
    mfcselect: MfcSelect
  },
  
  props: ["isCitizenRegistrationValid"],
  
  data() {
    return {
      currentDepartment: null,
      currentQuestionSubject: null
    }
  },

  methods: {
    setDepartment (department) {
      if (!this.isCitizenRegistrationValid && department.claims_only_from_region) {
        this.currentDepartment = null
      } else {
        this.currentDepartment = department;
      }
    },
    setQuestionSubject (questionSubject) {
      this.currentQuestionSubject = questionSubject;
    },
  },

  mounted() {
    if (typeof this.isCitizenRegistrationValid !== 'undefined') this.$store.commit('SET_STATE', {path: ['citizenHasInvalidRegistration'], value: !this.isCitizenRegistrationValid});
  }
}
