import axios from 'axios'
import toastr from "toastr"
import errorHandler from './error_handler'

const interceptor = axios.interceptors.response.use(function (response) {
  if (response.data.error) {
    //toastr['error'](error.title)
    errorHandler.handle(error)
    return Promise.reject(response);
  } else {
    return response;
  }
}, function (error) {
  if (error.response) {
    errorHandler.handle(error.response.data)
    return Promise.reject(error.response);
  } else if (error.request) {
    console.error(error.request);
    errorHandler.handle(error)
  } else {
    console.error('Error', error.message);
  }
  return Promise.reject(error);
});

export default interceptor;