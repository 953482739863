export default {
  bind: function (el, binding) {
    el.onclick = (e) => {
      e.preventDefault()
      if(document.querySelector('.pdfIframe')) {
        document.body.removeChild(document.querySelector('.pdfIframe'))
      }
        var iframe = document.createElement('iframe')
        iframe.className='pdfIframe'
        document.body.appendChild(iframe)
        iframe.style.display = 'none'
        iframe.onload = () => {
            setTimeout(() => {
                iframe.focus();
                iframe.contentWindow.print()
                URL.revokeObjectURL(binding.value)
            })
        }
        iframe.src = binding.value
    }
  }
}