<template>
  <div class="schedule__timeslots-container" v-if="timeslots.length > 0">
    <p class="title">Время консультации</p>
    <div class="schedule__timeslots" row="fs fs" wrap="">
      <div class="schedule__timeslot" v-for="(timeslot, index) in timeslots" @click="activeTimeslot(timeslot)" :class="{active: timeslot.active, free: timeslot.free}">
        <span>{{timeslot.start}}-{{timeslot.end}}</span>
        <input type="hidden" :name="'schedule[receptions_attributes]['+ index +'][id]'" v-model="timeslot.id">
        <input type="hidden" :name="'schedule[receptions_attributes]['+ index +'][start]'" v-model="timeslot.start">
        <input type="hidden" :name="'schedule[receptions_attributes]['+ index +'][end]'" v-model="timeslot.end">
        <input type="hidden" :name="'schedule[receptions_attributes]['+ index +'][active]'" v-show="false" v-model="timeslot.active">
      </div>
    </div>
  </div>
</template>

<script>
  import debounce from 'lodash/debounce'
  import map from 'lodash/map'

  export default {
    name: 'ScheduleTimeslots',
    props: {
      defaultTimeslots: {
        type: Array
      },
      scheduleConfig: {
        type: Object
      }
    },
    data () {
      return {
        timeslots: [],
      }
    },
    methods: {
      createTimeslots (config) {
        let startTime = this.$moment(config.start, "HH:mm");
        let endTime = this.$moment(config.end, "HH:mm").subtract(config.duration, 'minutes');
        this.timeslots = [];
        this.$store.commit('SET_STATE', {path: ["timeslotsLoaded"], value: false});
        const currentTime = this.$moment(`${new Date().getHours()}:${new Date().getMinutes()}`, "HH:mm");
        const todayInNewSchedule = this.$store.state.todayInNewSchedule;
        while (startTime <= endTime) {
          if (todayInNewSchedule && startTime <= currentTime) {
            startTime = startTime.add(config.duration, 'minutes');            
          } else {
            let buldTimeslot = {
            id: null,
            start: startTime.format("HH:mm"),
            end: (startTime = startTime.add(config.duration, 'minutes')).format("HH:mm"),
            active: true,
            free: true
            };
            this.timeslots.push(buldTimeslot);
          }
        }
        this.$store.commit('SET_STATE', {path: ["timeslotsLoaded"], value: true});
        return 1000;
      },
      activeTimeslot (timeslot) {
        if (timeslot.free) timeslot.active = !timeslot.active
      }
    },
    created() {
      if (this.defaultTimeslots) {
        map(this.defaultTimeslots, (e) => {
          e.start = this.$moment(e.start).format("HH:mm");
          e.end = this.$moment(e.end).format("HH:mm")
        })
        this.timeslots = this.defaultTimeslots
        this.$store.commit('SET_STATE', {path: ["timeslotsLoaded"], value: true});
      }
    },
    computed: {
      config() {
        if (this.scheduleConfig && (
          this.scheduleConfig.duration && this.scheduleConfig.duration > 0 &&
          this.$moment(this.scheduleConfig.start, 'HH:mm', true).isValid() &&
          this.$moment(this.scheduleConfig.end, 'HH:mm', true).isValid() &&
          (this.scheduleConfig.department_id && this.scheduleConfig.department_id.value) &&
          this.scheduleConfig.countSelectedDates >= 0
        )) {
          return this.scheduleConfig
        }
      }
    },
    watch: {
      config: function(newConfig) {
        if (newConfig) {
          this.createTimeslots(newConfig)
        } else {
          this.timeslots = []
          this.$store.commit('SET_STATE', {path: ["timeslotsLoaded"], value: true});
        }
      }
    },
  }
</script>

<style lang='scss' scoped>
  @import '../../assets/styles/variables';

  .schedule__timeslots-container {
    padding: 20px 0;
    border-radius: 6px;
    /*border: 1px solid rgb(218, 218, 218);*/
    height: 288px;
    max-height: 353px;
    width: 380px;
    max-width: 380px;
    
    p.title {
      margin: 0 0 10px 0;
    }
    .schedule__timeslots {
      overflow-y: auto;
      max-height: calc(100% - 58px);
      .schedule__timeslot {
        cursor: pointer;
        background: rgb(245, 245, 245);
        border-radius: 6px;
        padding: 8px 9px ;
        text-align: center;
        max-width: 122px;
        &:not(:nth-child(3n)) {
          margin: 0 8px 8px 0;
        }
        &.active {
          background: rgb(237, 83, 56);
          color: rgb(255, 255, 255);
        }
        &:not(.free) {
          background: rgb(245, 245, 245);
          color: rgb(196, 196, 196);
          cursor: default;
        }
        span {
          user-select: none;
        }
      }
    }
    &.for-new-page {
      padding: 20px 0 0 28px;
      width: 408px;
      max-width: 408px;
    }
  }


</style>
