import toastr from "toastr"

toastr.options = {
  positionClass: "toast-bottom-left"
}

window.toastr = toastr

export default {
  props: ['messages'],
  created() {
    _.each(this.messages, (m) => {
      toastr[m[0]](m[1])
    })
  },
  render() {}
}