<template>

<div class="custom-select">
  <div class="selected" :class="{ open: isOpen }" @click="isOpen = !isOpen">
    <span>{{ this.selected['text'] }}</span>
    <i class="mfcicon-arrow-down"></i>
  </div>

  <div class="items" :class="{ 'select-hidden': !isOpen }">
    <div class="item"
         v-for="option of sortOptions"
         :class="{ current: option['value'] == selected['value'] }"
         :value="option['value']"
         @click="sort(option)"
    >
      {{ option['text'] }}
    </div>
  </div>

  <select v-show="false">
    <option v-for="option in sortOptions" :value="option['value']" @click="sort(option)">
      {{ option['text'] }}
    </option>
  </select>
</div>

</template>


<script>
export default {
  data() {
    return {
      isOpen: false,
      selected: this.sortOptions.find(option => option['value'] == this.selectedOption)
    }
  },

  props: {
    sortOptions: {
      type: Array,
      required: true
    },
    selectedOption: {
      type: String,
      required: true
    }
  },

  methods: {
    sort(option) {
      this.selected = option
      const params = new URLSearchParams(window.location.search)
      params.delete("sorting")
      params.append("sorting", option['value'])
      Turbolinks.visit(`${window.location.pathname}?${params.toString()}`)
      this.isOpen = false;
    }
  }
}

</script>

<style lang="scss">

  @import '../../assets/styles/variables';

  .custom-select {
    color: #494949;
    position: relative;
    width: 252px;
    text-align: left;
    outline: none;
    font-size: 13px;

    .selected {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 36px;
      background-color: #ffffff;
      border-radius: 5px;
      border: 1px solid #e8e8e8;
      padding: 12px 12px 12px;
      cursor: pointer;
      user-select: none;
      

      &.open {
        border: 1px solid #dadada;
        border-radius: 5px 5px 0px 0px;

        i {
          color: black;
          transform: rotate(180deg);
        }
      }

      i {
        padding-top: 4px;
        color: #878787;
        transition: color 0.2s, transform 0.2s;
        z-index: 1;
      }
    }

    .items {
      border-radius: 0px 0px 5px 5px;
      overflow: hidden;
      border-right: 1px solid #dadada;
      border-left: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1;

      .item {
        background-color: white;
        padding: 13px 12px;
        min-height: 40px;
        cursor: pointer;
        user-select: none;
        
        &:hover {
          background-color: whitesmoke;
        }

        &.current {
          color: #e34226;
        }
      }
    }
  }

  .select-hidden {
    display: none;
  }

</style>