import { render, staticRenderFns } from "./DepartmentColors.vue?vue&type=template&id=d45012a4&scoped=true&"
import script from "./DepartmentColors.vue?vue&type=script&lang=js&"
export * from "./DepartmentColors.vue?vue&type=script&lang=js&"
import style0 from "./DepartmentColors.vue?vue&type=style&index=0&id=d45012a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d45012a4",
  null
  
)

export default component.exports