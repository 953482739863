import Bus from "../services/bus"
let self = null

class AnswerHandler {
  constructor() {
    this._type = 'AnswerHandler';
    self = this
  }

  handle(answer, request_id) {
    if (request_id) {
      Bus.$emit('api:answer:' + request_id, answer)
    }
    Bus.$emit('api:answer', answer)
  }
}


export default new AnswerHandler();