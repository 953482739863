<template>
    <div class="instruction-accordion-panel">
        <div class="instruction--panel--header" @click="toggle()">
            <slot name="header"/>
            <div class="arrow-container">
              <i class="mfcicon-arrow-down instruction-accordion-arrow-down" :class="{'instruction-accordion-arrow-up': show}"></i>
            </div>
        </div>

        <transition name="accordion"
                    @enter="start"
                    @after-enter="end"
                    @before-leave="start"
                    @after-leave="end">>
          <div class="instruction--panel--body" v-show="show">
              <slot name="body"/>
          </div>
        </transition>
    </div>
</template>
  
  
<script>
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        show: this.visible
      }
    },
    methods: {
      toggle() {
        this.show = !this.show;
      },
      start(el) {
        el.style.height = el.scrollHeight + "px";
      },
      end(el) {
        el.style.height = "";
      }
    }
  }
</script>
  
  
<style lang="scss" scoped>
  @import '../../assets/styles/colours';

  .instruction-accordion-panel + .instruction-accordion-panel {
    margin-top: 16px;
  }

  .instruction-accordion-panel {
    background-color: $bg--silver-100;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    border-radius: 12px;

    .instruction--panel--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-size: 20px;
      font-weight: bold;
      padding: 20px 28px 24px;
      line-height: 46px;
      color: $text-primary--gray-900;

      .arrow-container {
        display: flex;
        align-items: center;
        justify-content: center;

        min-width: 46px;
        min-height: 46px;
        background-color: $bg--white-100;
        border-radius: 100%;

        .instruction-accordion-arrow-down {
          display: flex;
          justify-content: center;
          align-items: center;
          
          font-size: 15px;
          color: $btn-default--orange-500;

          transition: .2s ease;
        }

        .instruction-accordion-arrow-up {
          transform: rotate(180deg);
        }
      }
    }

    .instruction--panel--body {
      padding: 0 28px;
      position: relative;

      .instruction-link {
        color: $link--blue-500;
        text-decoration: none;
      }

      padding-bottom: 24px;
    }

    .accordion-enter-active,
    .accordion-leave-active {
      will-change: height, opacity;
      transition: height 0.3s ease, opacity 0.3s ease, padding-top 5 ease, margin-top 5 ease;
      overflow: hidden;
    }

    .accordion-enter-from,
    .accordion-leave-to {
      height: 0 !important; /* без него высота не переопределится */
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      opacity: 0;
    }
  }
</style>