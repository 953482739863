<template>
  <div> 
    <img 
    :src="require('../../assets/images/claim-places/check-filled.svg')" 
    v-if="checkIconType"
    alt="check-filled"
    >
    <img 
    :src="require('../../assets/images/claim-places/check-empty.svg')" 
    v-else
    alt="check-empty"
    >
  </div>
</template>


<script>
  export default {
    name: 'FormCheckFieldIcon',
    props: {
      checkIconType: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style scoped>
  div {
    min-width: 16px;
  }
</style>