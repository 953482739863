import Vue from 'vue/dist/vue.esm'

import get from 'lodash/get'
import clone from 'lodash/clone'
import last from 'lodash/last'
import reduce from 'lodash/reduce'

const UNDEFINED_STATE = 'undefined_state_value'

const dynamicSetState = (state, payload, isError) => {
  const path = clone(payload.path)
  const clonedValue = clone(payload.value)

  if (path.join() === 'claim,question' && typeof(clonedValue) === typeof('')) {
    if ((!state.claim || !state.claim.question) && !state.oldQuestion) {
      state.oldQuestion = clonedValue
    } else if (clonedValue.trim() !== state.oldQuestion) {
      state.questionChanged = true
    } else {
      state.questionChanged = false
    }
  }

  const isArray = last(path) === '[]'
  const value = !isArray || isError ? clonedValue : (!!clonedValue ? [clonedValue] : [])

  if(isArray) {
    path.pop()
  }

  const lastToken = path.pop()

  const lastObj = reduce(
    path, 
    (current, token) => {
      const obj = get(current, token, UNDEFINED_STATE)
      return obj === UNDEFINED_STATE ? Vue.set(current, token, {}) : obj
    }, 
    state
  )

  Vue.set(lastObj, lastToken, value)
}

export {dynamicSetState}
