import moment from 'moment'

class MfcMoment {
  static install(Vue, options) {
    moment.locale('ru')
    Vue.prototype.$moment = moment
  }
}

export default MfcMoment
