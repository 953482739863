<template>
  <div>

    <div v-if="multiple">
      <button class="download-button" @click.prevent="downloadRecords">
        <span class="button-content" v-if="!isLoading">
          <i class="mfcicon-download icon-download"></i>
          <span>Скачать записи</span>
        </span>
        <span class="button-loader">
          <pulse-loader color="#EE5C45" :loading="isLoading" size="6px" margin="4px"></pulse-loader>
        </span>
      </button>
    </div>

    <div 
      class="download-single"  
      v-else
    >
      <button 
        @click.stop="downloadRecords"
        :content="dateOverdue && daysToOverdue && daysToOverdue <= 5 ? 'Запись скоро будет удалена' : 'Запись будет удалена ' + dateOverdue"
        v-tippy="{ placement : 'top', theme : 'light', maxWidth: 122, arrow: true, flip: false }"
        :class="{'loading': isLoading}" 
      >
        <div class="loader">
          <pulse-loader color="#EE5C45" :loading="isLoading" size="6px" margin="4px"></pulse-loader>
        </div>

        <div class="content">
          <i class="mfcicon-camera"></i>
          <span>Скачать</span>
        </div>

      </button>
      <div 
        class="description"
        :class="{warning: daysToOverdue && daysToOverdue <= 5}"
        v-if="dateOverdue"
      >
        до {{dateOverdue}}
      </div>
    </div>

  </div>
</template>

<script>
import JSZip from 'jszip'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { saveAs } from 'file-saver'
import isEmpty from 'lodash/isEmpty'
import toastr from "toastr"


export default {
  name: 'RecordDownload',
  components: {
    PulseLoader
  },
  props: {
    urls: {
      type: Array,
      required: true
    },
    filename: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    dateOverdue: {
      type: String,
      default: ""
    },
    daysToOverdue: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    downloadRecords() {
      if (this.isLoading) return

      this.isLoading = true
      var zip = new JSZip()

      function request(url, name, id) {
        return new Promise(function(resolve) {
          var httpRequest = new XMLHttpRequest()
          httpRequest.open("GET", url)
          httpRequest.responseType = 'blob'
          httpRequest.onload = function() {
            if (httpRequest.status == 200) {
              const blob = new Blob([this.response], { type: 'video/mp4' })
              zip.file(name, blob)
            }
            resolve({status: httpRequest.status, id: id})
          }
          httpRequest.send()
        })
      }

      Promise.all(this.urls.map(function(url) {
        return request(url.link, url.name, url.id)
      }))
      .then((response) => {
        if (!isEmpty(zip.files)) {
          zip.generateAsync({
            type: "blob"
          })
          .then((content) => {
            saveAs(content, this.filename)
          })
        }
        this.callErrorToaster(response)
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    callErrorToaster(response) {
      const statusesNotFound = response.filter(item => item.status === 404)
      const idsNotFound = statusesNotFound.map(item => `№${item.id}`)
      let message = ''

      if (response.length === 1 && statusesNotFound.length === 1) {
        message = "Запись удалена на сервере TrueConf и недоступна для скачивания."
      } else if (response.length > 1 && response.length === statusesNotFound.length) {
        message = "Записи удалены на сервере TrueConf и недоступны для скачивания."
      } else if (response.length > 1 && statusesNotFound.length > 0) {
        if (idsNotFound.length > 1) {
          message = `Записи консультаций ${idsNotFound.join(", ")} удалены на сервере TrueConf и недоступны для скачивания.`
        } else if (idsNotFound.length !== 0) {
          message = `Запись консультации ${idsNotFound[0]} удалена на сервере TrueConf и недоступна для скачивания.`
        }
      }
      if (message) {
        toastr.error(message)
        this.callDeleteRecordsJob(statusesNotFound)
      }
      
    },

    async callDeleteRecordsJob(claims) {
      try {
        await this.$axios.get(`${this.$routes.delete_records_admin_claims_path()}.json`, {params: {claim_ids: claims.map(item => item.id)}})
      } catch(e) {
        console.error(e);
      }
    }
  }
}
</script>

<style lang="scss">

  .download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 12px 10px;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    min-width: 142px;
    max-height: 38px;

    .button-content {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #494949;
      
      .icon-download {
        display: inline-flex;
        align-items: center;
        color: #878787;
        margin-right: 9px;
      }
    }

    .button-loader {
      display: flex;
      justify-content: center;
    }
  }

  .download-single {
    button {
      position: relative;
      padding: 0;
      font-size: 13px;
      line-height: 16px;
      position: relative;

      .content {
        display: flex;
        align-items: center;

        color: #EE5C45;
        font-weight: 700;
        .mfcicon-camera {
          margin-right: 4px;
          font-size: 18px;
          display: flex;
          align-items: center;
        }
      }
      .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &.loading {
        .content {
          opacity: 0;
        }
      }
    }
    .description {
      font-size: 11px;
      line-height: 18px;
      color: #878787;
      &.warning {
        color: #E64646;
      }
    }
  }
</style>
