<template>
  <div class="schedule__timeslots-container" v-if="showTimeslots">
    <p class="title">Время консультации</p>
    <input type="hidden" v-model="$store.state.reception_id" name="claim[reception_id]" v-show="false">
    <div class="schedule__timeslots" row="fs fs" wrap="">
      <div class="schedule__timeslot" v-for="(timeslot, index) in timeslots" @click="setReception(timeslot)" :class="{hide: (!timeslot.free && $store.state.show_only_free), disable: (!timeslot.free && (timeslot.id != defaultReceptionId && timeslot.id != claimReceptionId)), active: (timeslot.id == $store.state.reception_id)}">
        <span>{{timeslot.start}}-{{timeslot.end}}</span>
        <input type="hidden" :name="'schedule[receptions_attributes]['+ index +'][id]'" v-model="timeslot.id">
      </div>
    </div>
  </div>
</template>

<script>

  import map from 'lodash/map'
  import MfcCheckbox from '../form/MfcCheckbox.vue'

  export default {
    components: { MfcCheckbox },
    name: 'ClaimReceptions',
    props: {
      timeslotsColl: {
        type: Array
      },
      activeTimeslot: {
        type: Object
      },
      defaultReceptionId: {
        default: null
      },
      claimReceptionId: {
        default: null
      },
      namespace: {
        type: String
      }
    },
    data () {
      return {
        timeslots: [],
        receptionId: null
      }
    },
    methods: {
      parseTimeslots (timeslots) {
        timeslots = JSON.parse(JSON.stringify(timeslots))
        if (timeslots) {
          map(timeslots, (e) => {
            e.start = this.$moment(e.start).format("HH:mm");
            e.end = this.$moment(e.end).format("HH:mm")
          })
          return timeslots
        } else {
          return []
        }
      },
      setReception (timeslot) {
        if (timeslot.free || timeslot.id == this.defaultReceptionId || timeslot.id == this.claimReceptionId) {
          this.receptionId = timeslot.id;
          this.$store.commit('SET_STATE', {path: ["reception_id"], value: this.receptionId});
        }
      }
    },
    created() {
      this.receptionId = this.defaultReceptionId
      this.$store.commit('SET_STATE', {path: ["reception_id"], value: this.defaultReceptionId});
      this.timeslots = this.parseTimeslots(this.timeslotsColl)
    },
    computed: {
      showTimeslots() {
        if (this.$store.state.show_only_free) {
          return this.timeslots.length > 0 && this.timeslots.some(ts => ts.free)
        } else {
          return this.timeslots.length > 0
        }
      }
    },
    watch: {
      timeslotsColl: function (newVal) {
        this.timeslots = this.parseTimeslots(newVal)
      }
    },
  }
</script>

<style lang='scss' scoped>
  @import '../../assets/styles/variables';

  .schedule__timeslots-container {
    margin-left: 20px;
    padding: 20px 12px 24px;
    border-radius: 6px;
    /*border: 1px solid rgb(218, 218, 218);*/
    height: 353px;
    max-height: 353px;
    width: 431px;
    max-width: 431px;
    p.title {
      margin: 0 0 10px 0;
    }
    .schedule__timeslots {
      overflow-y: auto;
      max-height: calc(100% - 124px);
      .schedule__timeslot {
        cursor: pointer;
        background: rgb(245, 245, 245);
        border-radius: 6px;
        padding: 8px 9px ;
        text-align: center;
        max-width: 122px;
        margin: 0 8px 8px 0;
        &.disable {
          background: rgb(245, 245, 245);
          color: rgb(196, 196, 196);
          cursor: default;
        }
        &.active {
          background: rgb(237, 83, 56);
          color: rgb(255, 255, 255);
        }
        &.hide {
          display: none;
        }
        span {
          user-select: none;
        }
      }
    }
  }


</style>
