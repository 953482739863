
export default {
  props: {
    address: {
      default: ''
    },
    livingAddress: {
      default: ''
    },
  },
  data() {
    return({
      showLivingAddress: false
    })
  },
  methods: {
    changeAddress(val) {
      this.showLivingAddress = val
    }
  },
  mounted() {
    if (this.address == this.livingAddress) {
      this.showLivingAddress = false
    } else {
      this.showLivingAddress = true
    }
  },
}
