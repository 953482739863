import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import initStore from '../store'

import 'normalize.css'
import 'assets/styles'

window.ErrorHandler = require('../services/error_handler').default
window.AnswerHandler = require('../services/answer_handler').default
import interceptor from '../services/axios_interceptor'

require('../helpers/vueRegister')
require.context('../assets/images', true)

import toastr from "toastr"
import VueTheMask from "vue-the-mask"
import LazyComponent from "v-lazy-component"

Vue.use(VueTheMask);
Vue.use(LazyComponent);
Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const app = new Vue({
    el: "#mainapp",
    turbolinksDestroyEvent: 'turbolinks:request-end',
    store: initStore(),
    data: function() {
      return {
        toastr: toastr
      }
    },
    methods: {
      submit (formClassName) {
        setTimeout( () => {
          const form = document.querySelector(`form.${formClassName}`)
          if (form) form.submit()
        }, 300)
      }
    }
  })
})
