
export default {
  props: {
    form: {
      default: undefined
    }
  },
  methods: {
    submitDateRange () {
      let f;

      if (this.form) {
        f = document.querySelector('.' + this.form)
      } else {
        f = document.querySelector('form')
      }

      f.submit();
    }
  }
}
