import toastr from 'toastr'
import uniqueId from 'lodash/uniqueId'

export default {
  name: 'uploaderHelperMixin',
  data() {
    return {
    }
  },
  methods: {
    converter(size) {
      return Math.round((size / (1024 * 1024)) * 100) / 100
    },
    fileType(filename) {
      const array = filename.split('.')
      return array[array.length - 1]
    },
    fakeFileConfiguration(file) {
      return {
        description: file.name,
        for_delete: false,
        id: uniqueId(),
        loaded: true
      }
    },
    replaceObjectInArray(array, newObject, oldObject) {
      return array.map(obj => {
        if (obj.id === oldObject.id) {
          return newObject
        }
        return obj
      })
    }
  }
}
