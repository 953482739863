<script>
  import MfcTextarea from '../form/MfcTextarea'

  export default {
    name: 'QualityClaim',
    components: {MfcTextarea},
    props: ["quality"],
    data() {
      return {
        new: true,
        stars: [1,2,3,4,5],
        qualityMark: undefined,
        commentSize: 0,
        qualityMarkValue: ['Ужасно', 'Плохо', 'Нормально', 'Хорошо', 'Отлично']
      }
    },
    methods: {
      setQualityMark (value) {
        if (!this.quality.id) {
          this.qualityMark = value
        }
      },
      changeComment (comment) {
        this.commentSize = comment ? comment.length : 0
      }
    },
    mounted() {
      if (this.quality) {
        this.changeComment(this.quality.comment);
        this.qualityMark = this.quality.mark;
      }
    }
  }
</script>

<style lang="scss">
  .quality-box {
    background: #F9F5F0;
    border-radius: 5px;
    width: 810px;
    padding: 32px 16px;
    margin-bottom: 24px;

    &.only-show {
      .star { cursor: default }
    }

    .title {
      font-size: 28px;
      margin-bottom: 8px;
    }

    .star {
      position: relative;
      color: whitesmoke;
      border-radius: 3px;
      cursor: pointer;
      color: #bbbbbb;
      margin-right: 8px;
      &.active {
        color: #ed5338;
      }
      i:before {
        font-size: 30px;
      }
    }

    .quality-mark {
      margin-bottom: 20px;

      &-value {
        margin-left: 8px;
        color: #E04E39;
        line-height: 24px;
      }
    }

    &__body {
      max-width: 90%;
      margin-top: 24px;
      width: 100%;
      padding: 16px 16px 24px;
      border-radius: 6px;
      background: #ffffff;
      overflow-wrap: break-word;
      textarea {
        min-height: 128px !important;
        max-height: 128px !important;

        &:focus, &.filled {
          min-height: 98px !important;
        }
      }
      .actions {
        button { margin-right: 8px; }
      }
    }


  }
</style>
