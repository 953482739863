<template>
  <div class="accordion-panel">
        <div class="panel--header" @click="toggle">
            <slot name="header" />
            <i class="mfcicon-arrow-down accordion-arrow-down" :class="{'accordion-arrow-up': show}"></i>
        </div>

        <transition name="accordion"
                    @enter="start"
                    @after-enter="end"
                    @before-leave="start"
                    @after-leave="end">>
          <div class="panel--body" v-show="show">
              <slot name="body"/>
          </div>
        </transition>

    </div>
</template>


<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: this.visible
    }
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    }
  }
}
</script>


<style>
.accordion-panel {
  width: 100%;
  padding: 22px 24px;
  border: 2px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 4px;
}
.accordion-arrow-down {
  width: 32px;
  height: 32px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: .2s ease;
}
.accordion-arrow-up {
  transform: rotate(180deg);
}
.accordion-panel + .accordion-panel {
  margin-top: 24px;
}

.panel--header {
  cursor: pointer;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
}

.accordion_item {
  margin-top: 24px;
  display: flex
}
.accordion_item_background {
  background: #F9F5F0;
  border-radius: 4px;
  padding: 24px 8px 24px 24px;
}
.accordion_item_background_text {
  line-height: 24px;
}
.accordion_item_index {
  height: 26px;
  min-width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #1A1A1A;
  box-sizing: border-box;
}
.accordion_item_content {
  margin-left: 12px;
}

.accordion_item_content_text {
  line-height: 24px;
}
.accordion_item_content_text_small {
  margin-top: 8px;
  font-size: 14px;
  line-height: 18px;
  color: #878787;
}
.accordion_link {
  color: #175dc8;
  text-decoration: underline !important;  /* -> index.scss -> button, a {text-decoration: none !important;} */

}

.panel--body {
  position: relative;
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important; /* без него высота не переопределится  */
  opacity: 0;
}

.new-paragraph {
  display: block;
  margin-top: 6px;
}

</style>
