export default {
  data() {
    return {
      countClaimsAutomatically: Boolean
    }
  },
  methods: {
    changeCount(isChecked) {
      this.countClaimsAutomatically = isChecked
      console.log('auto: ', isChecked)
    }
  }
}