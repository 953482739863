<template>
  <div>
    <a :href=pathToCitizen class="button-box button primary">Перейти к профилю гражданина</a>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    props: {
      namespace: {
        type: String,
        required: true,
        default: ''
      },
      claimCitizenId: {
        type: String,
        required: false,
        default: ''
      }
    },
    computed: {
      pathToCitizen() {
        return this.namespace == 'expert' ? `/expert/citizens/${this.$store.state.citizenId || this.claimCitizenId}` : `/admin/handbooks/users/${this.$store.state.citizenId || this.claimCitizenId}`
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>