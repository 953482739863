import { yandexMap, ymapMarker } from 'vue-yandex-maps'

const settings = { // you may define your apiKey, lang and version or skip this.
  apiKey: 'xxx',
  lang: 'ru_RU',
  version: '2.1'
}

export default {
  props: {
    lat: String,
    lon: String
  },
  data() {
    return {
      settings: settings,
      marker: null,
      coords: [47.222078, 39.720349],
      zoom: 11,
      firstMarker: [this.lat, this.lon]
    }
  },
  components: { yandexMap, ymapMarker }

}