<template>
  <mfc-input-box :errors="cmpErrors">
    <label>{{label}}</label>
    <tinymce-editor style="min-height: 400px" api-key="m6cbdi5ve41qotpi7grmohe36u3yvcta8ot1oon0b54zheb0" :id="_uid.toString()" :init="options" :value="cmpValue" :name="name"/>
  </mfc-input-box>
</template>

<script>
  import MfcInputBox from './MfcInputBox'
  import inputMixin from "../../mixins/inputMixin"
  import Editor from '@tinymce/tinymce-vue';

  export default {
    name: "MfcHtmlEditor",
    mixins: [inputMixin],
    components: {
      MfcInputBox,
      'tinymce-editor': Editor
      },
    props: {
      initOptions: {
        type: [Object],
      }
    },
    data() {
      return {
        options: this.initOptions, 
        id: null
      }
    },
    methods: {
      async handleFile(file, success, failure) {
        if (!file || this.uploading) return
        try {
          this.uploading = true
          const formData = new FormData()

          formData.append('attachment[file]', file.blob())
          formData.append('attachment[kind]', "html_content_image")
          formData.append('attachment[type]', "Attachment::Document")

          const {data} = await this.$axios.post(this.$routes.attachments_path({format: "json"}), formData)
          success(data.resource.url)
        } catch (e) {
          console.error("ERROR", e)
          failure('Invalid JSON: ' + e);
          return;
        } finally {
          this.uploading = false
        }
      },
    },
    created () {
      this.options = this.options || {
        plugins: 'link image fullscreen quickbars code lists', 
        toolbar: 'undo redo styleselect bold italic numlist bullist alignleft aligncenter alignright outdent indent link quickimage code fullscreen', 
        menubar: false, 
        quickbars_insert_toolbar: "",
        images_upload_url: '/attachments', 
        images_upload_credentials: true, 
        images_upload_handler: this.handleFile,
        language_url: '/tinymce/langs/ru.js',
        language: 'ru'
      }
    }
  }
</script>

<style lang="scss" scoped>
  label {
    padding-bottom: 10px;
    padding: 10px;
    display: block;
    border: 1px solid #cccccc;
    border-bottom: none;
  }
</style>
