import flatpickr from "flatpickr"
import {Russian} from "flatpickr/dist/l10n/ru.js"
import 'flatpickr/dist/flatpickr.min.css'

export default {
  bind(el, {value: options, modifiers}, vnode) {
    const input = el.querySelector('input') || el

    const defaultFormat = 'd.m.Y'

    const defaultOptions = {
      locale: Russian,
      allowInput: true,
      onClose: (e, dateStr, instance) => {
        if (options.mode === 'range' && e.length == 1) instance.clear()
        input.blur()
      },
      dateFormat: defaultFormat,
      disableMobile: "true",
      wrap: true
    }

    input.setAttribute('data-input', 'true')

    if(!modifiers.nb) {
      const button = document.createElement('button')

      button.classList.add('btn-additional')
      button.setAttribute('type', 'button')
      button.setAttribute('data-toggle', 'true')
      button.innerHTML = '<i class="mfcicon-calendar-not-fill"></i>'

      el.appendChild(button)
    }

    flatpickr(el, {...defaultOptions, ...options})
  },
  unbind(el) {
    el._flatpickr.destroy()
    delete el._flatpickr
  }
}
