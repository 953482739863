<template>
  <div v-click-outside="hide" class="profile-dropper">
    <div class="avatar" @click="toggleDropdown" row="cn">
      <span class="initials">{{initials}}</span>
    </div>
    <div class="dropper-element" v-show="dropped" ref="dropper">
      <slot name="links"></slot>
    </div>
  </div>
</template>

<script>
  import ClickOutside from 'vue-click-outside'

  export default {
    name: 'MfcProfileDropper',
    props: {
      initials: {
        type: String
      },
      opened: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        dropped: false
      }
    },
    computed: {
      hasNotify() {
        return !!this.$scopedSlots.notifications
      }
    },
    beforeMount() {
      if(this.opened) {
        this.toggleDropdown()
      }
    },
    methods: {
      hide() {
        this.dropped = false
      },
      hideDropdown(event) {
        if (event.path.includes(this.$refs.dropper)) {
          return
        }
        this.dropped = false
        document.removeEventListener("click", this.hideDropdown)
      },
      toggleDropdown() {
        this.dropped = !this.dropped
      }
    },
    directives: {
      ClickOutside
    }
  }
</script>

<style lang='scss' scoped>
  @import '../../assets/styles/variables';

  $left-padding: 25px;

  .profile-dropper {
    position: relative;
  }

  .avatar {
    $size: 64px;
    position: relative;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: rgb(206, 155, 107);
    font-weight: 500;
    color: rgb(81, 21, 2);
    cursor: pointer;
    font-family: Circe;
    font-weight: 800;
    user-select: none;
    text-transform: uppercase;
  }

  .dropper-element {
    position: absolute;
    right: 0;
    top: 70px;
    width: 299px;
    border: 1px solid rgba(213, 213, 213, 1);
    border-radius: $default-border-radius;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 5px 7px -1px rgba(0, 0, 0, 0.15);
    z-index: $profile-dropper-zindex;

  }

  .list {
    &:not(:last-child) {
      padding-top: 24px;
      border-bottom: 1px solid rgba(213, 213, 213, 1);
    }
  }

  .list-element {
    display: block;
    padding: 0 30px 20px $left-padding;
    color: rgb(0, 0, 0);
    font-weight: 500;

    &.exit-link {
      padding-top: 28px;
      padding-bottom: 28px;
    }
    &.link-border {
      border-top: 1px solid rgba(213, 213, 213, 1);
    }
  }

</style>
