
export default {
  props: ['lat', 'lon'],
  data() {
    return {
      mfcLat: this.lat,
      mfcLon: this.lon
    }
  },
  created() {
  },
  methods: {
    setAddress (params) {
      this.mfcLat = params.lat
      this.mfcLon = params.lon
    }
  }
}