import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'

const settings = { // you may define your apiKey, lang and version or skip this.
  apiKey: '23426329-67cb-45f4-9573-8b47e76af5c4',
  lang: 'ru_RU',
  version: '2.1'
}

export default {
  props: {
    lon: String,
    lat: String
  },
  data() {
    return {
      settings: settings,
      marker: null,
      coords: [47.222078, 39.720349],
      zoom: 11,
      firstMarker: [this.lat, this.lon]
    }
  },
  methods: {
    initHandler (map) {
      let cmp_this
      cmp_this = this
      this.firstLoad(map, cmp_this)
      map.events.add('click', (e) => {
        this.clear(map);
        if (this.marker) {
          this.marker.geometry.setCoordinates(e.get('coords'))
        } else {
          this.marker = new ymaps.Placemark(e.get('coords'), {}, {
            iconPane: 'overlaps',
            draggable: true,
            preset: 'islands#redStretchyIcon'
          })
          map.geoObjects.add(this.marker);
        }
        this.create_building_marker(e.get('coords'), function(o) {
          cmp_this.$emit('enlarge-text', {
            lat: o.geometry._coordinates[0],
            lon: o.geometry._coordinates[1]
          })
          map.geoObjects.add(o);
        });
      })
    },
    getAddress (coords, params, func) {
      if (_.isUndefined(coords) || _.isEmpty(coords)) {
        return;
      }
      params.result = params.result || 1;
      ymaps.geocode(coords, params).then(func);
      return;
    },
    create_building_marker (adress_or_coords, cb) {
      return this.getAddress(adress_or_coords, {
        kind: 'house'
      }, function(res) {
        var o;
        o = res.geoObjects.get(0);
        return cb(o);
      });
    },
    clear (map) {
      this.marker = null;
      let objects;
      objects = [];
      map.geoObjects.each(function(o) {
        return objects.push(o);
      });
      _.each(objects, function(o) {
        return map.geoObjects.remove(o);
      });
    },
    firstLoad (map, cmp_this) {
      cmp_this.clear(map);
      if (!isEmpty(compact(cmp_this.firstMarker))) {
        cmp_this.create_building_marker(cmp_this.firstMarker, function(o) {
          cmp_this.$emit('enlarge-text', {
            address: o.properties.get('name'),
            lat: o.geometry._coordinates[0],
            lon: o.geometry._coordinates[1]
          })
          map.geoObjects.add(o);
        });
      }
    }
  },
  created() {
  },
  components: { yandexMap, ymapMarker }

}
